import { RootState } from "../store/store";
import { Snapshot } from "@etechinc/smartview-core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MapState {
  applicationReady: boolean;
  sceneReady: boolean;
  snapshot?: Snapshot;
  showSettings?: boolean;
}

const initialState = {
  applicationReady: false,
  sceneReady: false,
  snapshot: undefined,
  showSettings: false,
} as MapState;

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMapApplicationReady: (state, { payload }: PayloadAction<boolean>) => {
      state.applicationReady = payload;
    },
    setMapSceneReady: (state, { payload }: PayloadAction<boolean>) => {
      state.sceneReady = payload;
    },
    setMapSnapshot: (state, { payload }: PayloadAction<Snapshot | undefined>) => {
      state.snapshot = payload;
    },
    toggleMapSettings: (state) => {
      state.showSettings = !state.showSettings;
    },
  },
});

export const { setMapApplicationReady, setMapSceneReady, setMapSnapshot, toggleMapSettings } = mapSlice.actions;

export const mapApplicationReadySelector = (state: RootState) => state.map.applicationReady;
export const mapSceneReadySelector = (state: RootState) => state.map.sceneReady;
export const mapSnapshotSelector = (state: RootState) => state.map.snapshot;
export const mapShowSettingsSelector = (state: RootState) => state.map.showSettings;

export default mapSlice.reducer;
